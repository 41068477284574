body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*Footer */

/*responsive */
 @media(max-width : 991px){
   #header {
     height: 80vh;
     text-align: center;
   }
   #header .header-img {
     text-align: center;
   }
   #header .header-img img {
     width: 60%;
   }
 }

 @media (max-width : 768px) {
   #header {
     margin-top: 20px;
   }
   #header h1 {
      font-size: 28px;
   }
   #header h2 {
     font-size: 18px;
     margin-bottom: 30px;
   }
   #header .header-img img {
     width: 70%;
   }
 }