.navbaranim{
    position: relative;
    width:1%;
    left: 49.5%;
    opacity: 0;
    top:-5px;
    height:3px;
    padding:0px 0px;
    background: #11c220;
    z-index: -1;
    transition: .3s all ease 0s;
}
.navbaranimSit1{
    left:70px;
}
.navbaranimSit2{
    left:161px;
    width:82.5px;
}
.navbaranimSit3{
    left:240px;
}
.navbaranimSit4{
    left:320px;
}
.navbarhover{
    position: relative;
    font-family: 'Roboto', sans-serif;;
transition: .3s all;
}
li:hover .navbarhover{
    color:#11c220;
}
.navbar{
    padding:0px;
}
.submenuprogram
{
   display: none;
    background: #ffffff;
    color:rgb(0, 0, 0);
    width:200px;
    list-style: none;
    padding: 0px;

}
.submenuprogram ul{
    display: block;
    transition: .3s all;
}

.submenuprogram ul li{
    width:100%;
    cursor: pointer;
    transition: .3s all ease;
    font-family: 'Roboto', sans-serif;
    padding:14px;
    border-bottom: #e9e9e9 1px solid;
}
.submenuprogram ul a:hover li{
    background: #d3f5d5;
}
  .submenuprogram ul a:last-child li{
  
    border-bottom: none;
}
.submenuprogram ul a:first-child li{
    border-top:#11c220 3px solid;
}

.submenuprogram2
{
    background: #ffffff;
    color:rgb(0, 0, 0);
    width:200px;
    list-style: none;
    padding: 0px;
    

}
.submenuprogram2 ul{
    display: block;
    transition: .3s all;
}

.submenuprogram2 ul li{
    width:100%;
    cursor: pointer;
    transition: .3s all ease;
    font-family: 'Roboto', sans-serif;
    padding:5px;
    border-bottom: #e9e9e9 1px solid;
}
.submenuprogram2 ul li a{
    
}
.submenuprogram2 ul li  ul li {
padding:12px;
}
.submenuprogram2 ul li  ul:first-child{
padding-top:0px;
}
.programHover{
    transition:3s all;
}

.transitionElementProgram{
    display: none;
    width:100px;
    height: 100px;
    background-color: rgba(0, 0, 255, 0);
    position: absolute;
}
@media (min-width:991px){

    .programHover:hover .transitionElementProgram{
        display: block;
    }.programHover:hover .submenuprogram{
        display: block;
        
        position: absolute;
        transform:translateX(-27px);
    }
    
li:hover .navbaranim{
    width:20%;
    left:40%;
    opacity: 1;
}
}.desktopNav{
    display: none;
    position: absolute;
    width:100%;
    height:100%;
    position:fixed;
}
.navHamBurger{
    display: none;
}
@media (max-width:991px)
{
    .navbarcol {
   position:absolute;
   z-index: 10;
   top: 0px;
   background: white;
   margin: 0px;
    width:100%;
    height: 1000px;
    
}
.navHamBurger{
    display: flex;
    justify-content: center;
    width:100px;
    position: absolute;

    height: 50px;
}
}
@media (max-width:365px){
    .navHamBurger{
    }
}
.visibleMobile{
    display:block;
}
.fullscreenWrapper{
    transition:.5s all ease;
    position: absolute;
    border-top:2px #11c220 solid;
    border-bottom:2px #11c220 solid;
    margin-left: 10%;
    width:80%;
    left: 0px;
    top:65px;
    height: 239px;
    z-index: 10;
    z-index: 100;
    overflow:  hidden;
    background-color: white;box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;

}
.navWrapper{
    transition:.3s;
}
.navItemWrapper{
    position: absolute;
    width: 100%;
    padding:0px;
   
}
.navItemWrapper li{
    display: block;
}
.navItemMobile{
    font-size: 20px !important; 
}
.navClose{
    position: absolute;
    cursor: default !important;
    margin-left: 0%;
    width:100%;
    left: 0px;
    top:-100px;
    height: 1000px;
    z-index: 99;
    background-color: rgba(78, 78, 78, 0);
    border-right: 1px solid rgba(177, 122, 122, 0);
    position: fixed;

}
.fullscreenMove{
    transform-origin: top;
 transform: rotateX(90deg);
 opacity: 0.9;
 box-shadow: none;
}
.fullscreenMove2{
    margin-left: 100%; 
}
.hamMod{
    position: absolute;
    content: ' ';
    width:50px;
    height:3px;
    color:#11c220;
    background: #000000;
 
    transform:translate(-30px, 15px);
}
.ham1{   transition: .3s all ease;
    transform:translate(-30px, 0px);
}
.ham3{   transition: .3s all ease;
    transform: translate(-30px, 30px);
}
.ham2{
    transition: .1s all ease;
}
.hamTransform{

}
.hamTransform{
    transform :translate(-34px, 15px)rotateZ(45deg) }
.hamTransform2{
    opacity: 0;
}
.hamTransform3{
    transform :translate(-34px,15px)rotateZ(-45deg) ;
}
    
@media (max-width:650px) {
    .hamMod{
        
    width:35px;
    transform:translate(-30px, 15px);
    height:1.8px;
    }
    
.ham1{   transition: .3s all ease;
    transform:translate(-30px,4px);
}
.ham3{   transition: .3s all ease;
    transform: translate(-30px, 26px);
}
    
.hamTransform{
    transform :translate(-34px, 15px)rotateZ(45deg) ;
    }
    .hamTransform3{
        transform :translate(-34px,15px)rotateZ(-45deg) ;
        }
}
    .navDesktop{
        padding-right:20px;
    }
#nav1{
    font-size: 20px;
}
#nav2{
    font-size: 20px;
}
#nav3{
    font-size: 20px;
}
#nav4{
    font-size: 20px;
}
.navSmall{
    font-size: 19px;
}

.Navbar-image{
    border:none;
    width:170px;
    height:100%;
}
.navImg2{
        width:240px;
}
.menu_active{
    color:#11c220 !important;
}
@media (max-width:450px) {

    .headerEmail{
        display: none;
    }
    .navImg2{
        width:130px;
    }
}
.submenuMobile {
    position:absolute;
    top:0;
    width:100%;
    left:100%;
    visibility: hidden;
    transition:.3s;
}
.submenuUL{
    width:100%;
    padding:0px;
}
.submenuMove{
    opacity: 1;
    visibility: visible;
}
.submenuMove2{
    height:476px;

}
.submenuMove2 .navItemWrapper{
    transition: .3s all;
    transform:translateX(-100%);
}
.submenuMobile ul a li{
    
    font-family: 'Roboto', sans-serif;
}
.submenuMobile ul a:hover li{
    transition: .3s all;
color:#11c220 !important;
}


.subMDDIselected{
    color:#11c220;
    transform:rotateZ(180deg);
}
    .subMenuDropDownIcon
{
    border:none;
    
    transition:.5s all;
    transform:translateX(-20px) translateY(-1px) ;
   
}
.subMenuDropDownIcon2
{
    border:none;
    margin-left:91%;
    transition:.5s all;
   padding:0px;
}

.backiconnavli{
    padding:3px !important;
}

@media (max-width:782px)
{
    .fullscreenWrapper{
        width: 80%;
        left:0%;
    }
    
.fullscreenMove{
}
.fullscreenMove2{
    margin-left: 130%; 
}
}