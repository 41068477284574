

@media (min-width:900px)
{    
    .food{
        height: 450px;
          width:100%;
          background-attachment: fixed; 
          background-image: url('../../Image/images/About - Copy.jpeg');
          background-origin: border-box;
      background-size:100% ;
          background-repeat: no-repeat;
      }

      .headHeadAbout{
          font-size: 55px;
          color: black;
      }

.aboutImgRight{
    align-items:flex-start;
    display: flex;
}
.aboutImgRight img{
   
    width : 380px;
height : 550px;
}
.aboutgreenbgdiv{
    display: block;
}

.aboutGreenBGDivClass{
    display: none;
}
.aboutGreenBackground{
    position: relative;
    display:inline-block
}
}
.aboutGreenBackground::before{
    content: '';
    width: 300px;
    height: 480px;
    position:absolute;
    background-color: rgb(170, 247, 147);
   transform:translateX(20px);
    top : 65px;
    border-radius: 2px;
}
.aboutGreenBackground img{
width : 300px;
height : 480px;
position: relative;
top : 85px;
right : 0px;
transition:.3s;
border-radius: 2px;
box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
}
.aboutGreenHide{
    display: none;
}
@media (max-width:900px) {
    .aboutGreenBackground{
        display: none;
       
    }
    .aboutGreenBGDivClass{
        display: block;
    }
    .aboutgreenbgdiv{
        display: block;
        visibility: hidden;
    }.aboutGreenBackground::before{
        display: none;
        left:5%;
        bottom:5%;
    }
}
.aboutPhotoMobile{
    display: flex;
    justify-content: center;
}
.aboutPhotoMobile img{
  z-index: 1;
width : 250px;
height : 400px;
border-radius: 2px;
box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
}
.aboutPhotoMobile::after{
    content: '';
    width: 250px;
    height: 400px;
    position:absolute;
    background-color: rgb(170, 247, 147);
   transform:translateX(10px) translateY(-10px);
    
    border-radius: 2px;
}
.h1aboutWhyChoose{
    display: block;
    font-size: 35px !important;
}
.whychoosedivhead{
    display: none;
}
@media (max-width:900px) {
    .h1aboutWhyChoose{
display: none !important;
    }
    .whychoosedivhead{
        display: flex;
        justify-content: center;
    }
    .aboutGreenBackground img{
        display: block;
        top:45%;
    }
    .aboutGreenHide{
        display: block;;
    }
/* .aboutGreenBackground img{
    width : 0%;
    height : 90%;
    position: relative;
    top : 10%;
    }
*/

}
.alignbottomabout
{
    padding-top: 339px;
}
.subheadtext
{
    font-size:35px;
    padding:20px 20px 20px 20px;
}
@media (max-width:900px)
{
    .food{
   background-size: 0px;
  
   }
   .alignbottomabout
   {
       padding-top:     0px;
   }
   .headtext
   {
       font-size:200px;
       
   
   }
   .subheadtext
   {
       font-size: 20px;
   }
   .headHeadAbout{
       font-size: 35px;
   }
}
.gradientbg
{
    padding-top:10px;
    background-image:linear-gradient(54deg , #ffffff 0,#ffffff 50%,transparent 50%,transparent 100%);
}
.text{
    
    font-family: 'Antic Didone', serif;
letter-spacing: 1px;
font-size: 20px;
padding-left:20px;


}


.headtext{
    display: block;
font-family: 'Roboto', sans-serif;
font-size: 30px;
color:#03ac11;

}
.imgWhyChoose{
    height:110px ;
    width:290px ;
}
@media (min-width:900px) {
    
.headalignment
{
    text-align: left;
    padding:0% 7%;
}
.pointAbout{
    display: inline-block;
    transform:translateY(-4px)
}
}
.newcarsheading1{
    display: block;
}
@media (max-width:900px)
{.headalignment
    {
        text-align: left;
        padding:0% 5%;
    }
    .aboutImgRight{
        display: none;
    }
    .imgWhyChoose{
        height:100px ;
        width:250px ;
        margin-top:20px;
    }
    .newcarsheading1{
        display: none !important;
    }
}
.about{
    background: rgb(255, 255, 255);
    overflow: hidden;
}

 
.maincards{
    height: 80%;
    width: 100%;
    overflow: hidden;
    background-color: rgb(255, 255, 255);
    position: relative;
}

.firstletter::first-letter
{
    font-size:4.7rem;
    float:left;
    padding-top: 0px;
    line-height:50px;
}
.greenHighlight{
    color:#03ac11;
    font-size:22px;
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
}
.leftimageabout
{width: 500px;
    

}
.righttextabout
{
    padding-top: 100px;
    padding-bottom:100px;
    padding-left:50px;
    padding-right:50px;
    background-image: linear-gradient(
        rgba(0, 255, 149, 0.842), 
        rgba(183, 252, 200, 0.877)
      ), url('../../Image/services/fruits.jpg');
    background-size: 150%;
    
 
    
}

.textrightsideabout
{
    padding:0px 20px;
    font-family: 'Roboto', sans-serif;
    font-size: 20px;
list-style: none;    
}

.listabout{
    font-size: 20px;
    font-weight:bold;
    transition: .3s;
    position: relative;
}
.listabout:hover
{
    font-size: 21px;
    transition: .3s;
}
.listabout:hover:after{
    
    content:' ';
    display: block;
    position:absolute;
    border:1px solid rgba(68, 0, 11, 0);
    border-radius: 50px;
    width:69%;
    height: 3px;
    top:101%;
    background-image: linear-gradient(to right, #e600ff 0%, #17BAF9 51%, #e600ff 100%);
    animation-name: listhoveranim;
    animation-duration: .3s;
   
}
@keyframes listhoveranim
 {
    from {width:0%;left:34.5%;}
    to {width:69%;left:0%;}
}
.btnabout
{
    margin: 10px;
    outline:none;
    font-family: "Arial Black", Gadget, sans-serif;
    border:none;
    font-size: 20px;
    padding: 15px;
    text-align: center;
    text-transform: uppercase;
    transition: 0.5s;
    background-size: 200% auto;
    color: #FFF;
    box-shadow: 0 0 20px #eee;
    border-radius: 10px;
    width: 200px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
    cursor: pointer;
    display: inline-block;
    border-radius: 25px;
    background-image: linear-gradient(to right, #C570CE 0%, #17BAF9 51%, #e600ff 100%)
}
.btnabout:hover
{
    outline:none;
    background-color: black;
    border-color: black;
        box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
        margin: 8px 10px 12px;
        background-position: right center;
    
}

#loading{
    background: #03ac11;
}
.bordertemp1
{    background: #ffffff8e;
    border-radius: 20px;
    margin:10px;
    user-select: none;
    transition: .3s all;
    padding:0px  20px;
    position: relative;
    cursor: default;
}/*
.bordertemp1::first-letter{
   
    opacity: 0;
}*/
.bordertemp1:hover{
    background: white;
    letter-spacing: 2px;
    transition: .3s all;
}/*
.bordertemp1:hover::first-letter{
   color:#03ac11;
   font-weight: 900;
   opacity: 1;
    
}*/
.textabouttable
{   padding:15px 0px ;
    font-size: 20px;
}
.tickAbout{
    position: relative;
font-size: 0px;
transition:.3s;
}
.bordertemp1:hover .textabouttable .tickAbout{
display: inline;
transition:.3s all;
width:200px;
font-size: 30px;
   color:#03ac11;
font-weight: 900;
}
.bulletabout{
    display: none;
}
@media (max-width:900px)
{
    .tickAbout{
        font-size: 30px;
        opacity: 0;
    }.bordertemp1:hover .textabouttable .tickAbout{
        opacity: 1;
    }

    .bordertemp1:hover{
        background: white;
        letter-spacing: 0px;
        transition: .3s all;
    }
    .bulletabout{
        position: absolute;
        display: inline;
        transition: .3s all;
        color:#03ac11;
        transform-origin: center;
        transform: translateY(9px) translateX(-20px);
    }
    .bordertemp1:hover .bulletabout{
       opacity: 0;
       
    }
}
.bordertemp1:hover .textabouttable{
}
.expansionabout{
    position: absolute;
    display: block;
    background: white;
    border:white 1px solid;
    transform: translateY(-91.7px);
    overflow: hidden;
    animation: expabout .2s linear ;
}
@keyframes expabout {
    0%{ border:black;background: transparent;}
    10%{height: 100px;}
    100%{height: 200px; }
}
.aboutcarouseldot{
transition: .3s all;
    margin: 0px 5px;
    z-index: 3;
    cursor: pointer;
}
.aboutcarouselactive{
    color:rgb(0, 100, 5);
    transition: .3s all;

}
.aboutcarouselinactive
{transition: .3s all;
    color:rgb(125, 248, 76);
}.Review2{
    padding: 5% 0%;
    background-color: transparent;
}
.dropdownLine{
    display: inline-block;
    
}
.aboutimg2{
    display: none;
}
@media (max-width:700px) {
  
.aboutimg2{  display: block;
}}
.aboutlinkh6{

    font-family: 'Roboto', sans-serif !important; }
.aboutlinklink
{
    
        /* text-align: center; */
        width: 80%;
        padding:5px;
        /* padding-left: 10px; */
        font-size: 20px;
        letter-spacing: 1.2px;
        border-radius: 35px;
        background : transparent;
        color: green;
        border: 2px solid green;
        margin-top: 10px;
        right:9%;
        top: 0px !important;
        position: absolute;
}
.carouselMobileHeading{
    display: none !important;
}
.aboutMobileHeading{
    display: none !important;
}
@media (max-width:900px) {
    .carouselMobileHeading{
        display: block !important;
        font-size: 32px !important;
        padding:2% !important;
        text-align: center !important;
    }
    .aboutMobileHeading{
        display:inline !important;
    }
    .text{
        padding-right:30px;
    }.headtext
    {
        font-size:25px;
        padding-bottom: 20px;
    
    }
    
}