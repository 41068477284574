
/*
Contact
*/
.contact_div{
 background-color: rgb(250, 250, 250);   
}

.inputcontact input{
    background: transparent;
    text-decoration: none;
    border-radius: 1px;
    height:40px;
    border-width: 2.3px;
    background-color: #f5f4f7;
    font-family: 'Roboto', sans-serif;
    
    font-size: large;
 
}

@media (max-width:580px)
{
    .paddingtextarea{
        padding-left:30px;
        padding-right:30px;

    }
    .formheading{
        
        text-align: center;
        display: flex;
    }
}
.con{

    font-family: 'Roboto', sans-serif;

    font-size: 60px;
    color: Red;
}
.formcontact form {
    
    border-radius: 15px;
    border: 1px solid #03ac11;
    background: rgba(248, 248, 248, 0.973);
    padding-top: 5%;
    padding-bottom: 30px;
    padding-left:0px;
    padding-right: 0px;
    border-width: 4px;
    width: 100%;
    font-weight: 500;
    outline: none;
    transition: .5s;
    /*box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;*/
}
.paddingtextarea{
    padding-top: 10px;
    padding-bottom:10px;
}
.textmessage{
    
    height:125px;
    max-height: 800px;
    border:2px;    font-size: xx-large;
   
}
.jumbotron{
    background: rgba(248, 248, 248, 0.973);
    padding: 4%;
    font-family: 'Roboto', sans-serif;}

.contact-icon{
  
        color: #03ac11;
        display: flex;
        justify-content: center;
}
.icon-in-contact{
    font-size: 20px;
    font-weight: 500;
    font-family: 'Roboto', sans-serif;}

.inputcontact .btncontact{
  
border-radius: 50px;
display: flex;
    width:102px;
    border:none;
    border-radius: 50px;
    height:41px;
    font-family:  'Roboto', sans-serif;
   font-size: 22px;
    color:#03ac11;
    border:.5px solid;
    border-color: #03ac11;
  
    vertical-align:-webkit-baseline-middle;
    justify-content: center;
    box-shadow: none;

transition: .3s;
}
 


@media (max-width:650px) {
    .inputcontact .btncontact
    {
        font-size:20px;
    }
    
    
}
.btncontact:hover{
    transition: .3s;
    color:white;
    border-color: #03ac11;
    background-color: #03ac11;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.form-group
{
    box-shadow: none;
    outline: none;

    transition: all .3s;
}

.form-label
{
    font-size:large;
    position: absolute;
    display:block;
    opacity:1;
    transform:translateY(-1.75em) translateX(15px);
    transform-origin:0 0;
    transition:all .3s;
    font-family: 'Roboto', sans-serif;
cursor:pointer;

}
.form-control{
    box-shadow:none;
    background-color: rgba(0, 0, 0, 0.02);
    font-size: small;
    border-radius:5px;
    border-color: #ccc;
    border-style: none none solid none;
    width: 100%;
    transition: all .5s;
    outline: none;
   
   
}

.form-control::placeholder{
    color:transparent;
}
.form-control:focus-within{
    box-shadow: none;
    outline:none;
    border-color:#03ac11;
}

.form-group:focus-within{
    transform:scale(1.05, 1.05);
}

.form-control:focus +.form-label, .form-control:not(:placeholder-shown) +.form-label{
    transform:translateY(-3em) scale(0.75);
    cursor:default;
    color:#03ac11;
}
.borderbottom
{
display:inline-block;
position: relative;
}



@media (max-width:649px){
.borderbottom::after
{
    content:' ';
    display: block;
    position:absolute;
    width:10%;
    height: 6px;
    top:101%;
    left:45%;
    background-color:#03ac11;
}
.borderbottom::before
{
    content:' ';
    display: block;
    position:absolute;
    width:50%;
    height: 1px;
    top: 105%;
    left:25%;
    background-color:rgb(94, 94, 94);
    animation-name:borderbottomanim2;
    animation-duration: 3s;
}
}
.borderbottom2
{
display:inline-block;
position: relative;
}



@media (max-width:649px){
.borderbottom2::after
{
    content:' ';
    display: block;
    position:absolute;
    width:10%;
    height: 6px;
    top:101%;
    left:45%;
    background-color:#03ac11;
}
.borderbottom2::before
{
    content:' ';
    display: block;
    position:absolute;
    width:100%;
    height: 1px;
    top: 105%;
    left:0%;
    background-color:rgb(94, 94, 94);
    animation-name:borderbottomanim;
    animation-duration: 3s;
}
}
.borderbottom3
{
display:inline-block;
border-radius: 2px;
position: relative;
background: linear-gradient(to bottom, rgb(255, 255, 255) 50%, #03ac11 50%);
background-size: 100% 200%;
transition:.3s all;
background-position:left bottom;
animation: bestdietician;
animation-duration: 5.5s;

}
.bestdieticiantextcol
{
display:inline-block;
position: relative;
background: linear-gradient(to bottom, rgb(0, 0, 0) 50%, #ffffff 50%);
background-size: 100% 200%;
transition:.3s all;
background-position:left bottom;
animation: bestdietician;
animation-duration: 5.5s;
background-clip: text;
color:#03ac1100
}
.borderbottom3:hover{
    background-position: left bottom;
}
@keyframes bestdietician {
    0%{
        background-position:left top}
        50%{
            background-position:left top}
        100%{
            background-position:left bottom}
}

.borderbottom3::before
{
    content:' ';
    display: block;
    position:absolute;
    width:100%;
    height: 4px;
    top: 92%;
    left:0%;
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
    background-color:#03ac11;
    animation-name:borderbottomanim;
    animation-duration: 3s;
}

@media (min-width:650px) {
    

.borderbottom::after
{
    content:' ';
    display: block;
    position:absolute;
    width:10%;
    height: 6px;
    top:101%;
    left:45%;
    background-color:#03ac11;
   

}
.borderbottom::before
{
    content:' ';
    display: block;
    position:absolute;
    width:100%;
    height: 1px;
    top: 105%;
    left:0%;
    background-color:rgb(94, 94, 94);
    animation-name:borderbottomanim;
    animation-duration: 3s;

}}
@keyframes borderbottomanim {
    from {width:0%;left:50%;}
    to {width:100%;left:0%;}
}
@keyframes borderbottomanim2 {
    from {width:0%;left:50%;}
    to {width:50%;left:25%;}
}
.alertinline
{
    color:red;
    padding:auto;
     size:auto;
}

a:link{
    color:black;
    text-decoration: none;
}
a:visited{
    color: black;
    text-decoration: none;
}
/* a:link:hover
{
    transition: .3s;
    transform: translateY(-1px);
    color:black;
} */
.contact-icon{
    transition:.3s;
}
.contact-icon:hover
{
    transition:.3s;
    transform:translateY(-3px);
    color:red;
    
    
}
 .contact-icon-hover
{

    transition:.3s;
    transform:translateY(-3px);
    color:red;
    
}
.formheading
{
    font-size:52px;
    font-family: 'Roboto', sans-serif;
     color:Black;
     display:flex;
     justify-content: center;
}  

.contactheading
{
    font-size:50px;
}
.mapheading
{
    padding:5px;
    padding-bottom: 45px;
    font-weight: 600;
    font-size: 55px;
}

@media (max-width:660px)
{
    .contactheading{
        font-size:35px;
    }
    .formheading{
        font-size:30px;
    }
    .mapheading{
        font-size:37.5px;
        padding-bottom: 15px;
    }
}

.warningicon
{
    color:red;
    display:block;
    position:relative;
    left:-63px;
    top:13px;

}
.hiddenelement{
    visibility: hidden;
    display: none;
}
.newfont
{
    font-family: 'Roboto', sans-serif;

}
.warningborder{
    border-color: red;
}
.warningborder:focus-within{
    border-color: red;
}
.acceptedborder
{
    border-color: #03ac11;
}
.paddingmobile2
{
    visibility: hidden;
    display: none;

}
@media (max-width:650px) {
    .paddingmobile2
    {
        visibility: visible;
        display:block ;
        padding-left: 10%;
        left:30%;
        width:90%;
        font-size: large;
    }
    .paddingmobile
    {
        visibility: hidden;
        display: none;
    }
    .paddingfixform {
   
        
       margin-left: 0px;
       
        padding-left:0px;
        padding-right:0px;
        padding-bottom:10%;
    }
}
.textmessagelabel{
   position:absolute;
   transform:translateX(15px);
    font-family: 'Roboto', sans-serif;
    font-size:large;
    transform-origin: 0 0;
    transition:.3s;
}
.textmessagelabelanim{
transform:translateY(-1em) scale(0.75);
color:#03ac11;

}
