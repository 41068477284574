.modal {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.342);
 backdrop-filter: blur(4px);
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: all 0.3s ease-in-out;
    pointer-events: none;
    padding-left: 10px;
    padding-right: 10px;
  }
  
  .modal.enter-done {
    opacity: 1;
    pointer-events: visible;
  }
  
  .modal.exit {
    opacity: 0;
  }
  
  .dblockmodal
  {
    display: block;
    
  }
  .modal-content {
    
    width: 500px;
    border-radius: 10px;
z-index: 2000;
    border-color: rgba(255, 255, 255, 0);
    background-color: #fff;
    transition: all 0.3s ease-in-out;
    transform: translateY(-20px);
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  }
  
  .modal.enter-done .modal-content {
    transform: translateY(0);
  }
  
  .modal.exit .modal-content {
    transform: translateY(-20px);
  }
  
  .modal-header,
  .modal-footer {
    padding: 10px;
  }
  
  
  .modal-body {
    padding: 10px;
        justify-content: center;
    font-size: 110%;
   
  }
  @media (max-width:800px)
  {
    .modalheader
    {
      font-size:220%;
    }
    .modal-body
    {
      font-size: 140%;
    }
  }
  
.btnmodal
{
    width:100px;
    height:40px;
    padding:0px;
    border-radius:50px ;
    color: #03ac11;
    border-color: #03ac11;
}
.btnmodal:hover
{
    background-color: #03ac11;
    color:white;
}
.justifycontentcenter
{
    display: flex;
    justify-content: center;
    align-items: center;
    padding:10px;
}
.btnmodalclose{
    border-color:rgba(255, 255, 255, 0);
    border-radius: 4px;
    border-width: 1px;
    background-color:rgba(255, 255, 255, 0);
   position:relative;
   left:91%;  
   top:-29px;
   color:black;
   transition: .3s all;
   outline:none;
    
}
@media (max-width:450px)
{
  .btnmodalclose{
    left:83%;
  }
  
}
@media (max-width:768px) {
  

.modalmaintext
  {
    font-size: large;
  }
}
.btnmodalclose:hover{
color: red;
transition: .3s all;

}
.modalmaintext{
  font-weight: 500;
  font-size: 27px;
}
.modalmaintext2{
  padding: 0 0 0px;
  font-size: 19px;
  font-family: Roboto;
  text-align: center;

}
.vidmodal
{
  position: relative;
  top:-100px;
animation: tickanim;
animation-duration: 1s;

}
@keyframes tickanim {
  0% {top:-50px;opacity: 0;}
  100% {top:-100px}
}
.underlinebordermodal{
  height: 200px;
  content:' ';
  position: relative;
}
.underlinebordermodal::after
{
  content:' ';
z-index: -1;
  display: flex;
  position:absolute;
  width:498px;
  height: 43px;
  border:#0105ff 0.1px ;
  left: 1px;
  border-radius: 0px 0px 10px 10px;
  top: 210.5px;
  background-image:linear-gradient(180deg, #e600ff00 0%,#e600ff00 40.9999%, #0cdc7c 41%, #0cdc7c 100% );
  animation: underlineanim 2s;

}
@keyframes underlineanim {
from { top:145px; }  
to {top:210.5px;
}
}
@media (max-width:320px) {
  .underlinebordermodal::after{
    width: 98.5px;
    left:101px;
  }
}
@media (min-width:321px) {
  .underlinebordermodal::after{
    width:154px;
    left:101px;
  }
}

@media (min-width:376px) {
  .underlinebordermodal::after{
    width:204px;
    left:101px;
  }
}
@media (min-width:480px) {
  .underlinebordermodal::after{
    width:398px;
    left:51px;
  }
}
.heightincmodal
{
  display: flex;
  height: 100px;
}

.RippleModal
{
  border:none;
  border-radius: 50%;
  box-sizing: border-box;
  position: absolute;
  background: rgba(0, 255, 85, 0.25);
  animation: ripplemodal 2s linear infinite ;
  animation-duration: 5s;
}
@keyframes ripplemodal {
  0%  { width: 0px;
        height: 0px;
      }
  50% {
    opacity: 1;
  }
  100% {
    width: 300px;
    height: 300px;
    opacity: 0;
  }
  
}
.resituateripplemodal
{
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
}