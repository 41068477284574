@import url('https://fonts.googleapis.com/css2?family=Kaushan+Script&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fira+Sans+Condensed&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Caveat&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;400&display=swap');
* {
  user-select: none;
}
.navbar {
  background-color: #ffffff !important;

  box-shadow: 0px 0px 0px 0px grey;
}
.nav-name {
  font-size: 17px;
  font-weight: 100;
}
.menu_active {
  font-weight: bold;
}
home .header1 {
  color: yellow;
  text-decoration: none;
  font-weight: 600;
  background-color: rgb(9, 12, 12);
}
.header2 a {
  color: white;
}
@media (max-width: 991px) {
  .navbar-brand {
    font-size: 1.5rem !important;
  }

  .menu_active {
    font-weight: bold;
    border-bottom: none;
  }
}

.slide img {
  height: 80vh;
}

#header {
  width: 100%;
  height: 50vh;
  margin-top: 70px;
}

.head {
  background-color: #03ac11;
  font-weight: 600;
  color: white;
}

#header .brand-name {
  color: rgb(223, 62, 62);
}

#header h2 {
  color: rgb(32, 221, 32);
  font-size: 24px;
}

#header .btn-get-started {
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 10px 30px;
  border-radius: 50px;
  transition: 0.5s;
  color: greenyellow;
  border: 2px solid greenyellow;
  text-decoration: none;
}
/* DATA CSS  */

.data-heading {
  color: rgb(93, 155, 31);
}
.top-line {
  color: black;
  font-weight: 600;
  line-height: 10.8px;
}
.bold {
  font-size: 20px;
  color: rgb(32, 95, 51);
  font-weight: 600;
}
.first {
  font-size: 40px;
  font-weight: 600;
  color: green;
  font-family: 'Antic Didone', serif;
}
@keyframes whatsppScr {
  from {
    bottom: 20px;
  }

  to {
    bottom: 10px;
  }
}
.screen-bar img {
  width: 50px;
  height: 50px;
  position: fixed;
  bottom: 20px;
  right: 15px;
  z-index: 100;
  transform: scale(1.1);
  transition: all 250ms;
  border-radius: 50%;
}
.animates {
  transition: all 550ms;
  animation: shadow 2000ms infinite ease-in;

  opacity: 0.2;
}
.animates1 {
  transition: all 550ms;
  animation: shadow1 1s 1000ms infinite ease-out;

  opacity: 0.4;
}
@keyframes shadow {
  0% {
    transform: scale(0);
  }
  25% {
    transform: scale(0.2);
  }
  50% {
    transform: scale(0.4);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1.5);
  }
}
@keyframes shadow1 {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(1.2);
  }
  50% {
    transform: scale(1.3);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
.screen-bar img:hover {
  transform: translateY(-10px);
  transform: scale(1.3);
}

/* data CSS end */

.PROGRAMS-PHOTO {
  background-repeat: no-repeat;
  background-position: center;
  height: 500px;
  width: 100%;
}
@media (max-width: 500px) {
  .PROGRAMS-PHOTO {
    margin-top: 10px;
    height: 100%;
  }
}
.home-img .home-img-p {
  position: absolute;
  z-index: 3;
  font-family: 'Kaushan Script';
  font-weight: 1000;
  font-size: 30px;
  width: 50%;
  top: 40%;
  left: 8%;
  letter-spacing: 2px;
  word-spacing: 5px;
  display: flex;
  flex-direction: column;
}
.add-new {
  font-size: 28px;
  font-weight: 500;
  font-family: fantasy;
  color: rgb(184, 54, 3);
  margin-left: 10px;
}
@media (max-width: 1130px) {
  .home-img .home-img-p {
    font-size: 25px;
  }
}
.home-img .home-img-p a {
  text-align: center;
  width: 100%;
  padding: 8px 20px;
  /* padding-left: 10px; */
  font-size: 20px;
  border-radius: 35px;
  font-weight: 700;
  color: red;

  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  background: transparent;
  color: green;
  border: 2px solid green;
  margin-top: 0px;

  transition: all 250ms;
}
.home-img .home-img-p a:hover {
  background: green;
  color: white;
}
/* .home-img-fil{
 
    box-shadow: 0px 0px 100px 40px #e1dec9; 
    content: "";
    position: absolute;
    z-index: 1; 
    background: #e1dec9;  

    width: 100%;
    height: 100%; 
    top: 0;
    right: 40%;
    padding: 0;
    margin: 0;
} */
.home-img h6 {
  display: none;
}
@media (max-width: 600px) {
  .home-img .home-img-p {
    display: none;
  }
  .home-img h6 {
    display: block;
    position: absolute;
    z-index: 3;
    top: 20%;
    width: 50%;
    left: 0%;
    font-size: 19px;
    color: black;
    padding: 10px;
    font-family: 'Kaushan Script';
    letter-spacing: 3px;
    text-align: center;
    font-weight: 700;
  }
  .home-img h6 a {
    /* text-align: center; */
    width: 80%;
    padding: 5px;
    /* padding-left: 10px; */
    font-size: 20px;
    letter-spacing: 1.2px;
    border-radius: 35px;
    background: transparent;
    color: green;
    border: 2px solid green;
    margin-top: 10px;
    right: 9%;
    top: 60px;
    position: absolute;
  }
}
#header .btn-get-started:hover {
  background: greenyellow;
  color: ivory;
}

.header-img {
  text-align: right;
}
.LEFTCARD {
  height: 300px;
  float: left;
  display: flex;
  justify-content: center;
  flex: 1;
}
.LEFTCARD-Center {
  /* float: left;  */
  /* animation: rotate infinite 10s linear; */
}

@keyframes rotate {
  0% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(359deg);
  }
}
.center-div-for-program {
  position: absolute;
  top: -15%;
}
#header .animated {
  animation: up-down 2s ease-in-out infinite alternate-reverse both;
}
@-webkit-keyframes up-down {
  0% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(-10px);
  }
}

@keyframes up-down {
  0% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(-10px);
  }
}

/*
Footer Start
*/

.footer {
  display: absolute;
  background-color: #121116;
}

.list {
  padding-top: 5%;
  padding-left: 5%;
  color: white;
}
.list li {
  padding: 2%;
  text-decoration: none;
  color: yellowgreen;
}
.contactlist li {
  color: rgb(243, 242, 242);
  padding: 2%;
}
.list .footerlinks {
  font-weight: 500;
  color: rgb(218, 218, 218);
  text-decoration: none;
}
.list .footerlink :hover {
  color: rgb(63, 105, 8);
  border-bottom: 3px dotted red;
}
.buttomonservice {
  display: flex;
  justify-content: center;
}
.text p {
  letter-spacing: 2px;
  font-size: 15px;
  font-family: cursive;
  word-break: keep-all;
  color: white;
  box-sizing: border-box;
}

.icon {
  margin-right: 15px;
}

.endfoot {
  background-color: rgb(9, 12, 12);
  color: white;
  padding: 1%;
}
.icons a {
  text-decoration: none;
  color: ivory;
}

.facebook:hover {
  color: rgb(21, 149, 235);
  transform: translateY(-4px);
  transition: 500ms;
}
.whatsapp :hover {
  color: rgb(98, 170, 26);
  transform: translateY(-3px);
  transition: 500ms;
}
.insta:hover {
  color: rgb(255, 0, 242);
  transform: translateY(-4px);
  transition: 500ms;
}
.mail:hover {
  color: rgb(147, 207, 140);
  transform: translateY(-4px);
  transition: 500ms;
}

/*
p {
    font-size: 14px
}



.top-part {
    margin-top: 50px;
    background-color: #7961b3
}

.center-content {
    margin-top: 100px;
    margin-bottom: 100px
}

.btn-pink {
    border-radius: 0;
    background-color: #df3c72;
    color: #fff !important;
    letter-spacing: 2px;
    padding: 10px;
    padding-right: 20px;
    padding-left: 20px
}

.btn-pink:hover {
    background-color: #F50057
}

.line {
    border-top: 1px solid #66138a;
    width: 90% !important
}

.fa-mobile {
    font-size: 20px
}
*/
#contact {
  font-size: 15px;
  background: transparent;
}

.grey-text {
  color: lightgrey;
}

.items {
  width: 90%;
  margin: 0px auto;
  margin-top: 100px;
}

.slick-slide {
  margin: 10px;
}

.slick-slide img {
  width: 100%;
  border: 0px solid #fff;
}

/*
MAP CSS
*/

.map-container-5 {
  overflow: hidden;
  position: relative;
}
.map-container-5 iframe {
  height: 500px;
  width: 100%;
}

.servicecards {
  background-image: linear-gradient(120deg, #d4fc79 0%, #96e6a1 100%);
  letter-spacing: 1.3px;
  font-size: 20px;
  padding: 0;
  font-family: roboto;
  width: 100%;
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
}
.servicecards .title {
  margin-top: 10px;
}

.animate {
  animation: dry 990ms ease-in forwards;
  animation: up 400ms ease-in-out forwards;
}
/*{ 
    new service cards
}*/

.content {
  position: absolute;
  background-color: rgb(0, 0, 0, 0.7);
  color: rgba(255, 255, 255, 0.945);
  width: 100%;
  top: 100%;
  padding: 20px;
  height: 100%;
  font-size: 20px;
  letter-spacing: 1px;
  word-spacing: 8px;
  font-weight: 600;
  font-family: Arial, Helvetica, sans-serif;

  text-align: center;
}
.button-service {
  background-color: white;
  color: rgba(15, 9, 9, 0.637);
  font: 18px fangfang;
  font-weight: bold;
}
.content div {
  padding: 20px 0;
}

.card {
  overflow: hidden;
  height: 300px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border: none;
  border-radius: 5px;
  box-shadow: 2px 2px 10px 0px #bdb6b6;
}

@keyframes up {
  from {
    top: 100%;
  }
  to {
    top: 0%;
  }
}

.opacity {
  opacity: 0.7;
}

/*carousel home*/
.carousel {
  padding: 3%;
  position: relative;
}
.carousel::before {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  background-image: linear-gradient(120deg, #d4fc79 0%, #96e6a1 100%);
  width: 100%;
  height: 100%;
  opacity: 0.9;
  /* z-index: -1; */
}
.carousel1 {
  position: relative;
  z-index: 1;
}
.carousel1::before {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  background-image: linear-gradient(120deg, #d4fc79 0%, #96e6a1 100%);
  width: 100%;
  height: 100%;
  opacity: 0.9;
  z-index: -1;
}
.carousel2 {
  padding: 3%;
  position: relative;
}
.carousel2::before {
  position: absolute;
  content: '';
  top: 0;
  z-index: -1;
  left: 0;
  background-image: linear-gradient(120deg, #d4fc79 0%, #96e6a1 100%);
  width: 100%;
  height: 100%;
  opacity: 0.9;
}
.carousel22 {
  padding: 3%;
  position: relative;
}
.carousel22::before {
  position: absolute;
  content: '';
  top: 0;
  z-index: -1;
  left: 0;
  background-image: linear-gradient(120deg, #d4fc79 0%, #96e6a1 100%);
  width: 100%;
  height: 100%;
  opacity: 0.96;
}
.Home-carousel {
  letter-spacing: 1.5px;
  font-size: 55px;
  font-weight: 800;
  color: rgb(11, 70, 0.5);
  font-family: rubik;
  line-height: 40px;
  margin: 5px 0;
  gap: 1%;
}
@media (max-width: 400px) {
  .Home-carousel {
    font-size: 35px;
    letter-spacing: 3px;
  }
}
.Home-carousel p {
  position: relative;
  color: rgb(15, 124, 15);
}
.Home-carousel span {
  position: relative;
  color: rgb(0, 0, 0);
}
.Home-carousel p::before {
  content: '';

  border-radius: 4px;
  position: absolute;
  background: rgb(0, 0, 0);
  bottom: 0;
  left: 0;
  right: 0;
}

.appoint {
  background-attachment: fixed;
  background-image: url('https://s3-eu-west-2.amazonaws.com/images.s3.cpm-int.com/wp-content/uploads/2017/01/26151812/customer_care_WIDE1.jpg');
  background-size: 100%;
  background-repeat: no-repeat;
  padding: 30px;
}
.textin {
  color: rgb(60, 218, 12);
  padding: 5%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  text-shadow: 2px 3px 4px grey;
}
.buttoninhome {
  padding-bottom: 2%;
  display: flex;
  position: relative;
  justify-content: center;
  transition: color 1000ms;
}
.program {
  padding: 1%;
}
.link {
  text-decoration: none;
  color: rgb(12, 12, 12);
  font-family: 'Fira Sans Condensed', sans-serif;
  font-weight: 600;
  padding: 15px;
  letter-spacing: 3px;
  font-size: 17px;
  border: 0.1px solid rgb(241, 234, 234);
  margin-bottom: 1%;
  background: rgba(238, 238, 238, 0.918);
  position: relative;
  /* height:70px; */
  overflow: hidden;
}
.class {
  height: 100vh;
  overflow: hidden;
}
.link::after {
  z-index: 5;
  content: '';
  background-color: rgb(235, 11, 11);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  transform: translateX(-97%);
}

.link::before {
  z-index: -1;
  content: '';
  font-family: 'Merriweather', serif;
  background: red;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  transform: translate(-100%);
  transition: all 250ms linear;
}

.link:hover::before {
  transform: translateX(0);
}
.link:hover {
  z-index: 1;
  color: #ffffff;
}
.alllink {
  width: 100%;
  height: 500px;
}
.link span {
  margin-left: 5px;
}
.steps {
  height: 60vh;
  width: 100%;
  object-fit: cover;
  /* background-color: rgb(6, 116, 79); */
  opacity: 0.5;
  position: relative;
  background: black;
}
.photos {
  position: relative;
}
.photos::after {
  content: '\201F   Your diet is a bank account. Good food choices are good investments. 	\201D';
  color: rgba(255, 255, 255, 0.534);
  position: absolute;
  width: calc(100vw - 77vw);
  height: 100px;
  right: calc(100% - 95%);
  top: 20%;
  font-size: 22px;
  font-weight: 400;
  font-family: 'Rubik';
  letter-spacing: 1px;
}
.photos::before {
  position: absolute;
  content: '';
  left: 0;
  bottom: 0;
  background-color: rgb(0, 0, 0);
  width: 100%;
  height: 100%;
}
.Programs-head {
  position: relative;
  width: 100%;
}
/* .Programs-head::after{
    content:'"Your diet is a bank account. Good food choices are good investments."';
    color: white;
    position:absolute;
    width: 250px;
    height: 100px; 
    right: 5%;
    top: 20%;
    letter-spacing: 1px;

} */
@media (max-width: 400px) {
  .photos {
    display: none;
  }
}
.btn-for-service {
  background-color: transparent;
  color: rgb(22, 18, 18);
  padding: 5px 25px;
  font-size: 20px;
  border-radius: 50px;
  color: white;
  font-weight: 700;
  letter-spacing: 3px;
  outline: none;
  border: 2px solid rgb(146, 206, 57);
  transition: all 250ms;
  margin-bottom: 15px;
}

.btn-for-service:hover {
  background-color: rgb(142, 196, 61);
  color: rgb(255, 255, 255);
}
.service-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  text-transform: uppercase;
  font-size: 50px;
  color: white;
  font-family: Rubik, sans-serif;
  font-weight: 600;
  letter-spacing: 3px;
}

.image {
  position: relative;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.cardsforhome:hover {
  box-shadow: 1px 2px 1px 2px grey;
}

.testimonial {
  color: rgb(7, 7, 6);
  font-weight: 700;
  font-style: oblique;
}

.testimonial-card {
  border-radius: 30px;
}

.testimonial-card:hover {
  background-color: #92fe9d;
  transition: background-color 500ms ease-in;
}

.Comments {
  font-size: 15px;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.Comments:hover {
  background-image: linear-gradient(to top, #7028e4 0%, #e5b2ca 100%);
  transition: background-image 500ms ease-out;
}

.PROGRAM-HEADING {
  font-size: 2.5rem;
  padding: 20px 0px;
  font-family: Rubik, sans-serif;
  color: #212529;
  letter-spacing: 4px;
  font-weight: 600;

  position: relative;
}

.our-programs {
  font-size: 45px;
  font-weight: 600;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  letter-spacing: 2px;
  color: rgb(209, 30, 30);
}
.all-cards {
  padding: 0 50px;
}

@media (max-width: 500px) {
  .our-programs-head {
    padding: 0px;
  }
  .all-cards {
    padding: 0;
  }
}
.programs-head {
  font-size: 43px;

  font-weight: 600;
  font-family: Rubik;
  letter-spacing: 2px;
}
.programs-head-des {
  font-size: 20px;
  color: rgb(107, 109, 107);
  font-weight: 600;
  font-family: Rubik, sans-serif;
}
@media (max-width: 400px) {
  .programs-head-des {
    font-size: 15px;
  }
}
.our-programs-head {
  padding: 40px;
}
.in-phone::after {
  display: none;
}
.in-phone::before {
  display: none;
}

.logo-for-service {
  background-position: center;
  object-fit: contain;
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: 10px;
  opacity: 0.7;
}
@media (max-width: 400px) {
  .Programs-head {
    display: none;
  }
}
.programs {
  color: green;
  font-size: 45px;
  font-weight: 600;
}

.program-text {
  font-size: 20px;
}
.program-text2 {
  font-size: 18px;
}
.program-text1 {
  font-size: 19px;
}

.step {
  background-color: rgba(243, 240, 240, 0.726);
}
.heading-feature {
  display: flex;
  align-items: center;
  gap: 5%;
  padding: 3%;
  background-color: rgb(214, 221, 204);
  font: 23px caption;
}
.features {
  display: flex;
  align-items: center;
  padding: 30px;
  gap: 3%;
  color: rgb(41, 40, 40);

  font-size: 20px;
  font-weight: 600;
}

.Caro {
  padding: 4px;
}
.Caro .card-img-top :hover {
  color: #7028e4;
}

@media (max-width: 400px) {
  .BMI {
    padding: 0px;
  }
  .our-programs-head {
    display: none;
  }

  .in-phone::after {
    display: block;
  }
  .in-phone::before {
    display: block;
  }
  .programs-head {
    margin-bottom: 10px;
  }
}
@media (min-width: 900px) {
  .LEFTCARD-Center {
    display: none;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
.success-for-carousel {
  background-color: transparent;
  font-weight: 500;
  border: 0.1px solid rgb(45, 128, 24);
  transition: all 250ms ease-in-out;
  border-radius: 30px;
  padding: 5px 10px;
  word-spacing: 1px;
  color: rgb(30, 128, 30);
  font-family: roboto;
}

.success-for-carousel:hover {
  background: rgb(48, 138, 53);
}
.success-for-carousel:hover .Carousel-in-home-text {
  color: white;
}

@media (max-width: 400px) {
  .success-for-carousel {
    background: rgb(48, 138, 53);
  }
  .Carousel-in-home-text {
    color: white;
  }
}
.tick:hover .tick_mark::before {
  transition: all 250ms;
  box-shadow: 0px 0px 0px 6px #b8f090;
}
.success {
  background-color: transparent;
  font-weight: 500;
  border: 0.1px solid rgb(45, 128, 24);
  transition: all 250ms ease-in-out;
  border-radius: 30px;
  padding: 5px 10px;
  word-spacing: 1px;
  color: rgb(30, 128, 30);
  font-family: roboto;
}
.success_button {
  background-color: transparent;
  font-weight: 500;
  border: 0.1px solid rgb(248, 248, 247);
  transition: all 250ms ease-in-out;
  border-radius: 30px;
  padding: 7px 10px;
  word-spacing: 1px;
  color: rgb(30, 128, 30);
  font-family: roboto;
}
.success_button:hover {
  background-color: green;
  border-color: green;
  color: black;
}
@media (max-width: 400px) {
  .success_button {
    background-color: green;
    border-color: green;
  }
}

.success-for-service {
  color: #d4fc79;
  border: 0.1px solid rgb(255, 255, 255);
  font-size: 16px;
  padding: 8px;
  border-radius: 25px;
  transition: all 250ms;
}
.success-for-service:hover {
  color: rgb(255, 255, 255);
  background: #92fe9d;
  border-width: 2px;
}
.success-for-service-text {
  font-size: 17px;
  padding: 0;
  margin: 0;
  color: rgb(249, 249, 249);
}
.success:hover {
  color: rgb(255, 255, 255);
  background-color: rgb(48, 138, 53);
}
.BMI-text {
  color: rgb(25, 84, 173);
  font-size: 40px;
  font-weight: 700;
}

.result {
  position: relative;
  width: 350px;
  background: white;
  box-shadow: 0px 0px 4px 1px grey;
  text-align: center;
  word-wrap: normal;
  word-break: break-all;
  border-radius: 10px;
  padding: 24px;
  visibility: hidden;
}
.close {
  position: relative;
  float: right;
  line-height: 0px;
  top: -20px;
  left: 5%;
  height: 30px;
  padding: 0px;
  margin: 0px;
  border-radius: 15px;
}
@media (max-width: 400px) {
  .main {
    display: block;
  }
}
.main {
  display: flex;
  justify-content: space-between;
}
.close:hover {
  color: red;
}

.slideBMI {
  visibility: visible;
  animation: results 1s ease-out;
  left: 4%;
}

@keyframes results {
  from {
    left: -30%;
  }
  to {
    left: 10%;
  }
}
@media (max-width: 400px) {
  .setBMI {
    padding: 15px;
  }
  .close {
    top: -7px;
    left: 0%;
  }
  .BMI-whole {
    width: 100%;
  }
  .status {
    width: 0;
  }
  .padding-button {
    padding-bottom: 15px;
    padding: 10px;
  }
  .input-BMI input {
    z-index: 0;
  }

  .category {
    font-size: 16px;
    line-height: 1.5em;
  }
  .slideBMI {
    left: 4%;
    padding: 0px;
    margin-bottom: 35px;
  }
  /* }
    .calculator{
        padding-top: 30px;
        padding-bottom: 10px;
      
    } */
}
.categoryStatus {
  font-size: 16px;
  line-height: 1.5em;
}

.calculator {
  font-size: 40px;
  font-family: 'Times New Roman', Times, serif;
  letter-spacing: 3px;
  font-weight: 600;
  color: #070e50;
  justify-content: flex-end;
  /* background-color: rgb(248, 248, 248); */
}
.underline {
  content: '';
  height: 20px;
  background-color: white;
  border-bottom: 2px solid red;
  position: absolute;
  top: 0;
  left: 0;
}
.BMI-whole {
  width: 50%;
}

.Output_for_BMI {
  left: -105%;
  transition: all 300ms;
}
.outnow {
  left: 5%;
}
@media (max-width: 400px) {
  .outnow {
    left: 7%;
    max-width: 340px;
  }
}
@media (max-width: 450px) {
  .outnow {
    left: 7%;
    max-width: 340px;
  }
}
@media (max-width: 370px) {
  .outnow {
    left: 3%;
    max-width: 320px;
  }
}
.cross:hover {
  color: red;
  transform: scale(1.3);
  cursor: pointer;
}

.text-bmi {
  display: flex;
  justify-content: flex-end;
}

.owner1 {
  position: absolute;
  height: 100%;
  right: 150px;
  top: 70px;
}
@media (max-width: 400px) {
  .owner1 {
    right: 40px;
    top: 25px;
  }
  .para:first-child {
    height: 200px;
  }
  .cross {
    color: red;
    transform: scale(1.2);
  }
}
.p {
  position: absolute;
  z-index: 1;
  top: 18px;
  left: 10px;
  font-weight: 500;
  background: white;
  width: 80px;
  text-align: center;
  line-height: 7px;
  transition: all 250ms;
}

.google_text input {
  border-color: blue;
}
.border-color-red input {
  border-color: rgb(255, 12, 12);
}
.google_text .p {
  top: -2%;
  transform: scale(0.8);
}
.google_random {
  border: 2px solid springgreen;
}

.input-class {
  width: 100%;
  z-index: 100;
  overflow: hidden;
  text-align: center;
  outline: none;
  border: none;
  border-radius: 10px;
  font-size: 40px;
  background-color: rgba(241, 241, 241, 0.973);
}

.Hidden {
  padding-top: 2px;
  display: flex;
  justify-content: space-between;
}
.redbars {
  border-radius: 10px;
  width: 25%;
  height: 7px;
  background-color: red;
}
.yellowbars {
  border-radius: 10px;
  width: 25%;
  height: 7px;
  background-color: rgb(218, 200, 44);
}

.greenbars {
  border-radius: 10px;
  width: 25%;
  height: 7px;
  background-color: rgb(44, 218, 53);
}
.bluebars {
  border-radius: 10px;
  width: 25%;
  height: 7px;
  background-color: rgb(44, 56, 218);
}
.bars {
  display: flex;
  border-radius: 3px;
  margin-top: 10px;
}

.dot {
  line-height: 0px;
  height: 20px;
}
.barText h6 {
  padding: 10px;
  line-height: 20px;
  word-break: keep-all;
  text-align: left;
  font-weight: 400;
  font-family: roboto;
}
.bottom-status {
  line-height: 0px;
  text-align: left;
  font-size: 18px;
  font-weight: 600;
}
.status {
  display: flex;
  width: 100%;
  justify-content: center;
  gap: 2px;
}
@media (max-width: 400px) {
  .floty {
    position: relative;
    top: -20px;
    width: auto;
    background-color: #ffffffff;
    float: left;
    left: 40px;
    color: grey;
    z-index: 0;
    line-height: 5px;
    text-align: center;
    font-weight: 500;
  }
}

@media (min-width: 400px) {
  .floty {
    position: relative;
    top: -25px;
    width: auto;
    background-color: #ffffffff;
    float: left;
    left: 40px;
    color: grey;
    z-index: 0;
    line-height: 5px;
    text-align: center;
    font-weight: 500;
  }
}
@keyframes new {
  from {
    top: -10px;
  }
  to {
    top: -37px;
    left: 20px;
    font-size: 14px;
    padding-left: 5px;
    padding-right: 5px;
  }
}

@media (min-width: 400px) {
  @keyframes upperText {
    from {
      top: -30px;
    }
    to {
      top: -45px;
      left: 20px;
      font-size: 14px;
      padding-left: 5px;
      padding-right: 5px;
    }
  }
}

@media (max-width: 400px) {
  @keyframes upperText {
    from {
      top: -30px;
    }
    to {
      top: -38px;
      left: 20px;
      font-size: 14px;
      padding-left: 5px;
      padding-right: 5px;
    }
  }
}
/* @media (max-width : 340px) {
    @keyframes upperText {
        from{
            top:-30px;
        }                        // one plus media query
        to{
            top: -41px;
            left : 20px;
            font-size: 14px; 
            padding-left: 5px;
            padding-right: 5px;
        }
    
    } 
} */
@media (max-width: 400px) {
  .btn-for-program {
    font-size: 13px;
  }
}

.leave input {
  border: 2px solid blue;
  transition: all 100ms ease-in;
}

.BMI-unit-part {
  border: 2px solid springgreen;
  border-radius: 5px;
  padding: 8px;
}
.units {
  color: rgb(150, 150, 150);
  font-weight: 600;
  box-shadow: none;
  border: 2px solid springgreen;
}
.units:focus {
  box-shadow: none;
  border: 2px solid springgreen;
}
.bmi-units {
  position: relative;
  width: 100%;
  top: -70%;
  font-size: 13px;
  background-color: white;
  z-index: 100;

  text-align: right;
  color: grey;
}
select {
  background: white;
}
.googleImage {
  width: 80%;
}
.button-on-program a {
  color: white;
}

.goggleCards {
  display: flex;
  align-items: center;
  padding: 15px;
  box-sizing: border-box;
}
.goggleCards .img {
  box-sizing: border-box;
  border-radius: 50%;
  padding: 10px;
  display: flex;
  transform: scale(1.1);
  line-height: 15px;
  font-size: 22px;
  color: rgb(255, 255, 255);
  background: #7028e4;
}
.goggleCardsimg2 {
  box-sizing: border-box;
  border-radius: 50%;
  padding: 10px;
  display: flex;
  transform: scale(1.1);
  line-height: 15px;
  font-size: 22px;
  color: rgb(255, 255, 255);
}

.nameofperson {
  font: 17px rubik, sans-serif;
  font-weight: 600;
  line-height: 1.5rem;
  margin-left: 5px;
}
.Person {
  display: flex;
  flex-direction: column;
  padding-left: 20px;
}
.text-content p {
  font-size: 17px;
  font-family: 'Roboto', sans-serif;
  font-weight: 650;

  /* font-weight: 600; */
  line-height: 1.5rem;
}
.program-ul-text {
  list-style-type: none;
  padding: 0px 50px;
  line-height: 37px;
  letter-spacing: 0.7px;
  color: #575757;
  font-family: 'Roboto';
}
@media (max-width: 400px) {
  .program-ul-text {
    padding: 0 0px 0 13px;
  }
}

.reviewcards {
  background-color: #ffffff;
  border-radius: 15px;
  width: 100%;
  padding: 15px;
  border-left: 25px solid rgb(54, 121, 228);
  transition: all 250ms;
}
.reviewcards:hover {
  box-shadow: 0px 4px 10px 0px #90c6f97c;
  transform: scale(1.01);
}
.Heading-border {
  background-color: white;
  padding: 18px;
  border-radius: 18px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
@media (max-width: 700px) {
  .center-Heading {
    display: none;
  }
}

@media (max-width: 450px) {
  .write-review {
    justify-content: center;
  }
  .write-review span {
    font-weight: 600;
  }
  .write-review img {
    margin-right: 0px;
  }
  /* .center-Heading{ 
        display: none; 
    } */
  .button {
    display: flex;
    justify-content: center;
  }
}
.button-review a {
  color: rgba(255, 255, 255, 0.959);
  font-weight: 600;
  border-radius: 15px;
}
.main-head {
  padding-bottom: 5%;
  font-size: 40px;
  /* color: rgba(2, 0, 136, 0.699);
    */
  color: rgb(17, 17, 17);
  font-weight: 800;
  letter-spacing: 5px;
  display: flex;
  font-family: serif;
  justify-content: center;
}
.phone-head {
  display: none;
}
@media (max-width: 400px) {
  .main-head {
    font-size: 20px;
    text-align: center;
    display: none;
  }
  .phone-head {
    display: block;
    text-align: center;
    font-size: 40px;
    font-family: 'Kaushan Script';
    padding: 10px 0;
    font-weight: 500;
    letter-spacing: 5px;
    color: rgb(107, 2, 2);
  }
}

.write-review {
  display: flex;
  align-items: center;
}
.write-review span {
  font-size: 22px;
  margin-left: 5px;
}
@media (min-width: 400px) {
  .btn-for-program {
    font-size: 16px;
  }
}
.btn-for-program {
  padding: 8px 10px;
  border-radius: 20px;
  border: 1px solid grey;
  font-family: inherit;
  transition: all 250ms;
  font-weight: 500;
  color: rgb(255, 255, 255);
}
.btn-for-program:hover {
  background: rgb(68, 110, 202);
  color: white;
}
.center-Heading {
  color: black;
  font-size: 35px;
  font-family: roboto;
  font-weight: 600;
  letter-spacing: 3px;
}
@media (max-width: 998px) {
  .center-Heading {
    font-size: 30px;
  }
}
.Review {
  z-index: 5;
  padding: 5%;
  /* background-image: linear-gradient(-225deg, #DFFFCD 0%, #90F9C4 48%, #39F3BB 100%); */
}
.Newcards {
  display: flex;
  justify-content: space-around;
}
@media (max-width: 980px) {
  .Newcards {
    display: block;
  }
}

.maincardsFOR {
  transition: 0.3s all ease;
  width: auto;
  padding: 2%;
}
.ENDbutton {
  display: flex;
  align-items: center;
}
.buttonLink:link {
  text-decoration: none;
  color: grey;
  font: 15px roboto;
  font-weight: 500;
}
.buttonLink:hover {
  color: blue;
}
.rating {
  margin-left: 0px;
}
.PHONE-CLASS {
  padding: 0 20px;
}
/* All media query */

/* /////////////////////////////////////////////////////////////////// */
.content-in-phone {
  /* background: #a8ff78;  /* fallback for old browsers */
  /* background: -webkit-linear-gradient(to right, #78ffd6, #a8ff78);  Chrome 10-25, Safari 5.1-6 */
  /* background: linear-gradient(to right, #78ffd6, #a8ff78); W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  padding: 0 16px;
  height: 320px;
  width: 324px;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.952);
}
.content-2 {
  font-size: 17px;
  letter-spacing: 1.2px;
  font-family: roboto;
  padding: 5px 0;
  font-weight: 600;
}
.Phone-bmi {
  display: flex;
  flex: 1;
  justify-content: space-between;
}
.answer {
  text-align: center;
  width: 50%;
}
.Phone-bmi h1 {
  font: 50px roboto;
  font-weight: 400;
  background-color: rgb(221, 209, 209);
}
.Slider {
  position: absolute;
  left: -100%;
  bottom: 0;
  z-index: 1;
  padding: 20px;
}

.slide-phone {
  animation: slidephone 550ms forwards;
}
@keyframes slidephone {
  from {
    left: -100%;
  }
  to {
    left: 0%;
  }
}
@media (max-width: 500px) {
  .result {
    display: none;
  }
  .PHONE-CLASS {
    padding: 50px 10px;
  }
}
@media (max-width: 998px) {
  .result {
    left: 15%;
    margin-bottom: 10px;
  }
}
@media (min-width: 492px) {
  .Slider {
    display: none;
  }
}

.floaty {
  animation: upperText 70ms forwards;
}

.first-indicator {
  margin-left: 20px;
}
.second-indicator {
  margin-left: -28px;
}
.third-indicator {
  position: relative;
  left: -9%;
}
.indicator {
  display: flex;
  justify-content: space-around;
  font: 15px small-caption;
  margin-top: 1px;
  font-weight: 550;
}
.program-header {
  padding-top: 2%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1%;
}

.OurStyle {
  font-size: 60px;
  font-weight: 700;
  color: green;
}
.serviceStyle {
  font-size: 60px;
  font-weight: 500;
}

.react-multi-carousel-dot-list {
  position: absolute;
  bottom: -3px;
  padding: 20px 0;
  transition: all 250ms;
}
.react-multi-carousel-list {
  padding: 0px 0;
  position: initial;
}
.react-multi-carousel-dot button {
  width: 30px;
  height: 0px;
  display: none;
  background: white;
  border-color: white;
  border-radius: 0%;
  /* border-color: rgb(13, 122, 58); */
}
@media (max-width: 400px) {
  .react-multi-carousel-dot button {
    width: 20px;
    display: block;
  }
}
.react-multi-carousel-dot--active button {
  background-color: rgb(1, 182, 1);
  border-color: rgb(1, 182, 1);
  width: 30px;
  height: 0px;
  box-shadow: 0px 0px 20px 2px rgb(197, 191, 191);
}
.react-multiple-carousel__arrow--left {
  left: calc(1% + 1px);
  outline: none;
}
.react-multiple-carousel__arrow--right {
  right: calc(1% + 1px);
  outline: none;
}
.react-multiple-carousel__arrow:hover {
  background-color: rgba(0, 0, 0, 0.336);
  outline: none;
}
.react-multiple-carousel__arrow {
  background-color: transparent;
  outline: none;
}
.react-multi-carousel-list button:focus {
  outline: none;
}

.__loader {
  height: calc(100vh - 120px);
  width: 95vw;
  position: relative;
}
.__loader img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
